let _canAdoptStylesheetCached: Option<boolean>;
function _canAdoptStylesheet(): boolean {
  if (_canAdoptStylesheetCached != null) return _canAdoptStylesheetCached;
  try {
    new CSSStyleSheet();
  } catch (err) {
    return (_canAdoptStylesheetCached = false);
  }
  return (_canAdoptStylesheetCached = true);
}

export const css = (s: TemplateStringsArray | string): Style.Compat => {
  if (_canAdoptStylesheet()) {
    const sheet = new CSSStyleSheet();
    sheet.replaceSync(`${s}`);
    return sheet;
  }

  return Style.make(s.toString());
};

export namespace Style {
  export type Compat = HTMLStyleElement | CSSStyleSheet;

  export const canAdoptStylesheet = _canAdoptStylesheet;

  export const $borderBoxStyle = css`
    * {
      box-sizing: border-box;
    }
  `;

  // export const $theme = css`
  //   :host {
  //     --headline-size: var(--headline-size);
  //     --h1-size: var(--h1-size);
  //     --h2-size: var(--h2-size);
  //     --h3-size: var(--h3-size);
  //     --h4-size: var(--h4-size);
  //     --h5-size: var(--h5-size);
  //     --h6-size: var(--h6-size);
  //     --p-size: var(--p-size);
  //     --caption-size: var(--caption-size);
  //     --small-size: var(--small-size);

  //     --cap-height: var(--cap-height);
  //     --baseline: var(--baseline);

  //     --font-size: var(--font-size);
  //     --line-height-ratio: var(--line-height-ratio);
  //   }
  // `;

  // export const $theme = css`
  //   :host {
  //     --headline-size: inherit;
  //     --h1-size: inherit;
  //     --h2-size: inherit;
  //     --h3-size: inherit;
  //     --h4-size: inherit;
  //     --h5-size: inherit;
  //     --h6-size: inherit;
  //     --p-size: inherit;
  //     --caption-size: inherit;
  //     --small-size: inherit;

  //     --cap-height: inherit;
  //     --baseline: inherit;

  //     --font-size: inherit;
  //     --line-height-ratio: inherit;

  //     --background-color-elevated: inherit;
  //   }
  // `;

  export const $theme = css`
    :host {
    }
  `;

  export function make(style: string): HTMLStyleElement {
    const $style = document.createElement("style");
    $style.textContent = style;
    return $style;
  }

  const cache: Map<Compat, HTMLStyleElement> = new Map();
  export function makeWith(s: Compat): HTMLStyleElement {
    if (s instanceof HTMLStyleElement) {
      return s.cloneNode(true) as HTMLStyleElement;
    }
    const $cached = cache.get(s);
    if ($cached) {
      return $cached.cloneNode(true) as HTMLStyleElement;
    }
    const style = [...s.cssRules].map((r) => r.cssText).join("\n");
    const $template = make(style);
    cache.set(s, $template);
    return $template.cloneNode(true) as HTMLStyleElement;
  }
}
