import { Result } from "../result";
import { ResponseResult, TypedApi } from "../typed-api";
import { GoogleAuth } from "./google";

type AuthRoutes = {
  "/auth/signin/google": {
    post: {
      config: {
        query: {
          code: string;
        };
      };
      response: void;
    };
  };
  "/auth/id": {
    get: {
      response: {
        User?: Option<{
          id: string;
        }>;
        Anonymous?: Option<{}>;
      };
    };
  };
  "/auth/signout": {
    post: {};
  };
};

export const AuthApi = TypedApi.make<AuthRoutes>();

export namespace Auth {
  export async function startGoogleLogin(
    clientId: string,
    scope: string[]
  ): Promise<ResponseResult<void>> {
    const code = await GoogleAuth.login(clientId, scope);
    return await AuthApi.postResult("/auth/signin/google", {
      query: {
        code,
      },
    });
  }
}
