import { LonaWebComponent, template } from "@lona/component";
import { component } from "@lona/component-decorators";
import { StudyCard } from "./study-card";
import { css } from "@lona/component-styles";
import { DomUtils } from "@lona/dom";
import { EXAMPLE } from "./examples";
import { LiveData } from "@lona/livedata";
import { Actions } from "../routes/index.root";
import { SimpleKeyframe } from "@lona/keyframe";
import { $qs, $qsa, $qs_maybe } from "@lona/dom-selectors";
import { RewardCard } from "./reward-card";

@component({
  name: "langilo-study-card-carousel",
})
export class StudyCardCarousel extends LonaWebComponent {
  private cards: Option<LiveData<StudyCard.Prop[]>>;
  private $rewardCard = RewardCard.make();

  constructor() {
    super();
    this.$rewardCard
      .assignStyles({
        transform: this.positionToTransform("center"),
        zIndex: String(0),
      })
      .addPointerEvent({
        onClick: () => {
          this.$rewardCard.flip();
        },
      });
  }

  bind(cards: LiveData<StudyCard.Prop[]>) {
    this.cards = cards;
    this.cards.addListenerAndTrigger(this, this.bindCards.bind(this));
    this.appendChild(this.$rewardCard);
  }

  private bindCards(cards: StudyCard.Prop[]) {
    // todo: need proper diffing
    // const ids = cards.map((card) => card.id);
    // const renderedIds = $qsa("[card-id]").forEach(($e) =>
    //   $e.getAttribute("card-id")
    // );

    // center/left/right
    const positions: StudyCard.Prop[] = [];
    for (const [idx, card] of cards.entries()) {
      let position: StudyCardCarousel.Position = "center";
      switch (positions.length) {
        case 0:
          position = "center";
          positions.push(card);
          break;
        case 1:
          position = "left";
          positions.push(card);
          break;
        case 2:
          position = "right";
          positions.push(card);
          break;
        default:
          break;
      }

      const $card =
        $qs_maybe<StudyCard>(`[card-id='${card.id}']`, this) ??
        StudyCard.make();

      $card.bind(card, () => {
        Actions.markAsReviewed(card);
        const cardWidthPx = 280;
        const offToCenter = (400 - cardWidthPx) / 2;
        $card.assignStyles({
          opacity: String(0),
          transform: `translate(${offToCenter + 40}px, 40px) rotate(6deg)`,
          pointerEvents: "none",
        });
      });
      $card
        .assignStyles({
          transform: this.positionToTransform(position),
          zIndex: String(99999 - idx),
          filter: position != "center" ? `blur(0.7px)` : "",
        })
        .addPointerEvent({
          onClick: () => {
            if (position == "center") {
              $card.flip();
            } else {
              const newCards = [
                cards[idx],
                ...cards.slice(0, idx),
                ...cards.slice(idx + 1),
              ];
              this.cards!.update(newCards);
            }
          },
        });

      if (!$qs_maybe<StudyCard>(`[card-id='${card.id}']`, this))
        this.appendChild($card);
    }
  }

  private positionToTransform(position: StudyCardCarousel.Position): string {
    const cardWidthPx = 280;
    const offToCenter = (400 - cardWidthPx) / 2;

    switch (position) {
      case "center":
        return `translateX(${offToCenter}px)`;
      case "left":
        return `scale(0.95) translateX(${offToCenter - 60}px) rotate(-6deg)`;
      case "right":
        return `scale(0.95) translateX(${offToCenter + 60}px) rotate(6deg)`;
    }
  }

  static $styles = [
    css`
      #root {
        position: relative;
        width: 400px;
        height: 440px;
      }

      slot::slotted(*) {
        position: absolute;
        top: 0;
        left: 0;
      }
    `,
  ];
  static $html: Option<HTMLTemplateElement> = template`
    <std-row id=root>
      <slot name=reward-card></slot>
      <slot></slot>
    </std-row>
  `;
}

export namespace StudyCardCarousel {
  export type Position = "left" | "center" | "right";
}
