import { LonaWebComponent, template } from "../component";
import { Row } from "../component-builtin";
import { component } from "../component-decorators";
import { css } from "../component-styles";
import { Constants } from "../constants";
import { DomUtils } from "../dom";
import { EditDistance } from "../edit-distance";
import { $$, mutate } from "../fastdom";
import { GESTURE_MANAGER } from "../gesture-manager";
import { Hotkeys } from "../hotkeys";
import { LazySync } from "../lazy";
import { AngleRightIcon, LonaIcons } from "../ui/icons";
import { ListCellLayout } from "./list/list-cell-layout";
import { Placeholder } from "./placeholder";

@component({
  name: "std-dropdown",
})
export class Dropdown<Data> extends LonaWebComponent {
  private state: Option<Dropdown.State<Data>>;
  private $container: LazySync<DropdownContainer<Data>> = new LazySync(() => {
    const $container = DropdownContainer.make();
    DomUtils.registerKeyListeners($container, {
      onEnterKey: this.onEnterKey.bind(this),
      onArrowKeyVertical: this.onArrowKeyVertical.bind(this),
    });
    return $container as DropdownContainer<Data>;
  });
  private observer: Dropdown.ScrollAncestorObserver =
    new Dropdown.ScrollAncestorObserver(this, () => {
      const $container = this.$container.maybeGet();
      if (!$container) return;
      this.layoutDropdownContainer();
    });
  private resizeObserver: ResizeObserver = new ResizeObserver(([entry]) => {
    this.currentSize = {
      width: entry.contentRect.width,
      height: entry.contentRect.height,
    };
  });
  private currentSize: Option<Size>;

  constructor() {
    super();

    GESTURE_MANAGER.addPointerEvent(this.$("root"), {
      onClick: () => {
        this.$("search-input").toggleAttribute("hidden", false);
        this.$("selected").toggleAttribute("hidden", true);

        const isOpen = this.toggleAttribute("open", true);
        if (isOpen) {
          this.$("search-input").focus();
          DomUtils.setEndOfContenteditable(this.$("search-input"));
        } else {
          this.$("search-input").blur();
        }

        this.toggleDropdownContainer(true);

        GESTURE_MANAGER.setOnClickOutside(this.$("root"), ($e) => {
          if ($e == this.$container.maybeGet()) return;
          this.dismiss();
        });
      },
    });

    DomUtils.registerKeyListeners(this, {
      onEnterKey: this.onEnterKey.bind(this),
      onArrowKeyVertical: this.onArrowKeyVertical.bind(this),
    });
    DomUtils.registerKeyListeners(this.$("search-input"), {
      onEnterKey: () => {},
      onArrowKeyVertical: () => {},
      onTextChangeDEPRECATED: (t) => this.bindRows(t),
    });
  }

  private dismiss() {
    this.toggleAttribute("open", false);
    this.toggleDropdownContainer(false);
    GESTURE_MANAGER.clearOnClickOutside(this.$("root"));

    if (this.children.length > 0) {
      this.$("search-input").toggleAttribute("hidden", true);
      this.$("selected").toggleAttribute("hidden", false);
    } else {
      this.$("search-input").toggleAttribute("hidden", false);
      this.$("selected").toggleAttribute("hidden", true);
    }
  }

  private onEnterKey() {
    const $container = this.$container.maybeGet();
    if (!$container) return;
    const row = $container.getHoveredRow();
    if (!row) return;
    this.bindSelectedRow(row.row);
    this.dismiss();
  }

  private onArrowKeyVertical = (key: "ArrowUp" | "ArrowDown") => {
    const $container = this.$container.maybeGet();
    if (!$container) return;
    if (!this.state) return;
    const currentRow = $container.getHoveredRow();
    if (!currentRow) {
      $container.bindHoveredRow(this.state.first());
      return;
    }

    const direction = key == "ArrowUp" ? -1 : 1;
    $container.bindHoveredRow(this.state.next(currentRow, direction));
  };

  private toggleDropdownContainer(force: boolean) {
    const $container = this.$container.get();
    if (force) {
      DomUtils.assignStyles($container, {
        position: "absolute",
        top: "0px",
        left: "0px",
      });
      this.layoutDropdownContainer();
      this.bindRows();
      $$.mutate(() => {
        Hotkeys.pushEscapeHandler(this, () => this.dismiss());
        document.body.appendChild($container);
      });
    } else {
      Hotkeys.clearEscapeHandler(this);
      DomUtils.removeFromDom($container, false);
    }
  }

  @mutate
  private layoutDropdownContainer() {
    const $container = this.$container.maybeGet();
    if (!$container) return;
    if (!this.currentSize) return;
    const r = this.getBoundingClientRect();
    const { x, y } = { x: r.left, y: r.top };
    $container.assignStyles({
      width: this.currentSize.width + "px",
      transform: `translate(${x}px, ${y + this.currentSize.height - 1}px)`,
    });
  }

  connectedCallback() {
    this.resizeObserver.observe(this);
    this.observer.observe();
  }

  disconnectedCallback() {
    this.resizeObserver.unobserve(this);
  }

  bind(
    rows: Dropdown.Row<Data>[],
    onSelectedRow: (row: Dropdown.Row<Data>) => void = Constants.EMPTY_FUNCTION
  ) {
    this.state = new Dropdown.State(rows, {
      onHoveredRow: (row) => {
        const $container = this.$container.maybeGet();
        if (!$container) return;
        $container.bindHoveredRow(row);
      },
      onSelectedRow: (row) => {
        const $container = this.$container.maybeGet();
        if (!$container) return;
        this.bindSelectedRow(row);
        onSelectedRow(row);
      },
    });
  }

  bindSelectedRow(row: Option<Dropdown.Row<Data>>) {
    DomUtils.clearChildren(this);
    if (row) {
      const $row = Dropdown.RowContent.make(row?.content);
      $row.slot = "selected";
      this.appendChild($row);
    }
    this.$("search-input").toggleAttribute("hidden", row != null);
    this.$("selected").toggleAttribute("hidden", row == null);
  }

  private bindRows(query: Option<string> = null) {
    const $container = this.$container.maybeGet();
    if (!$container) return;

    DomUtils.clearChildren($container, false);
    $container.dropdownScrollToTop();

    const layout = this.state?.bind(query);
    if (!layout) return;

    for (const row of layout.rows) {
      $container.appendChild(row.$row);
    }

    if (layout.candidate) {
      $container.bindHoveredRow(layout.candidate);
    }
  }

  static $styles = [
    Placeholder.$style,
    css`
      :host {
        --dropdown-outline-color: #aaa;
        --dropdown-background-color: var(--background-color);

        width: 200px;
      }

      #root {
        border: 1px solid var(--dropdown-outline-color);
        background-color: var(--dropdown-background-color);
        border-radius: 8px;
        padding: 4px;
        align-items: center;

        width: 100%;
        transition: border-radius 0.15s ease;
        cursor: pointer;
        z-index: 1;
      }

      :host([open]) #root {
        border-bottom-left-radius: 0px;
        border-bottom-right-radius: 0px;
      }

      #search-row {
        padding: 0px 8px;
        align-items: center;
        flex-grow: 1;
        min-height: 28px;
      }

      #search-input {
        width: 100%;
        padding-inline-start: 8px;
      }

      *:focus {
        outline: none;
      }
    `,
  ];

  static $icons = [LonaIcons.symbol(AngleRightIcon)];
  static $html = template`
    <std-row id=root>

      <std-row id=search-row>
        <slot id=selected name=selected></slot>
        <p id=search-input contenteditable placeholder="Type to Search"></p>
      </std-row>

      <std-icon-button square style=margin-right:8px;>
        <svg height="16" width="16" style=transform:rotate(90deg)>
          <use href="#angle-right" />
        </svg>
      </std-icon-button>

    </std-row>
  `;
}

@component({
  name: "std-dropdown-container",
})
export class DropdownContainer<Data> extends LonaWebComponent {
  private hoveredRow: Option<Dropdown.BoundRow<Data>>;

  constructor() {
    super();
    this.addEventListener(
      "scroll",
      (e) => {
        e.stopPropagation();
      },
      {
        passive: true,
      }
    );
  }

  bindHoveredRow(row: Option<Dropdown.BoundRow<Data>>) {
    const $buttonBackground = this.$("button-background");
    if (!row) {
      this.hoveredRow = null;
      $buttonBackground.style.height = "0px";
      return;
    }
    if (row.$row.parentElement != this) return;

    this.hoveredRow = row;
    const parentR = this.$("dropdown").getBoundingClientRect();
    const r = row.$row.getBoundingClientRect();
    DomUtils.assignStyles($buttonBackground, {
      height: r.height + "px",
      transform: `translateY(${
        r.top - parentR.top + this.$("dropdown").scrollTop
      }px)`,
    });
    row.$row.scrollIntoView({
      behavior: "smooth",
      block: "center",
      inline: "center",
    });
  }

  getHoveredRow(): Option<Dropdown.BoundRow<Data>> {
    return this.hoveredRow;
  }

  dropdownScrollToTop() {
    this.$("dropdown").scrollTop = 0;
  }

  static $styles = [
    css`
      :host {
        --dropdown-outline-color: #aaa;
        --dropdown-background-color: var(--background-color);

        position: absolute;
        top: 0px;
        left: 0px;
        height: 160px;
        width: 100px;
      }

      #root {
        height: 100%;
        width: 100%;
      }

      #dropdown {
        position: relative;
        height: 100%;
        width: 100%;

        padding: 4px 8px;
        border-radius: 8px;
        border-top-left-radius: 0px;
        border-top-right-radius: 0px;

        border: 1px solid var(--dropdown-outline-color);
        background-color: var(--dropdown-background-color);
        overflow: scroll;
      }

      #button-background {
        position: absolute;
        top: 0;
        left: 4px;
        width: calc(100% - 8px);
        background-color: var(--hover-color);
        border-radius: 10px;
        transition: transform 0.15s ease, height 0.15s ease;
        z-index: 0;
        pointer-events: none;
      }

      slot::slotted(*) {
        cursor: pointer;
      }
    `,
  ];

  static $html: Option<HTMLTemplateElement> = template`
    <div id=root>
      <std-col id=dropdown>
        <div id=button-background></div>
        <slot></slot>
      </std-col>
    </div>
  `;
}

export namespace Dropdown {
  export type Legacy<T> = Dropdown<T>;
  export const Legacy = Dropdown;

  export type RowContent = string | HTMLElement;

  export namespace RowContent {
    export function make(content: RowContent): HTMLElement {
      if (content instanceof HTMLElement) {
        return content;
      }
      const $l = ListCellLayout.makeText({
        title: content,
      });
      $l.style.setProperty("--p-hover-color", "transparent");
      return $l;
    }
  }

  export type Row<Data> = {
    selected?: Option<boolean>;
    content: RowContent;
    searchIndicies?: string[];
    data: Data;
  };

  export type BoundRow<Data> = {
    row: Row<Data>;
    $row: HTMLElement;
  };

  export type SearchResult<Data> = {
    row: BoundRow<Data>;
    minDistance: number;
    minDistanceIndex: Option<string>;
  };

  export class State<Data> {
    private rows: BoundRow<Data>[] = [];
    private currentSearch: Option<{
      query?: Option<string>;
      results: BoundRow<Data>[];
    }>;

    constructor(
      rows: Dropdown.Row<Data>[],
      callbacks: {
        onHoveredRow: (row: Dropdown.BoundRow<Data>) => void;
        onSelectedRow: (row: Dropdown.Row<Data>) => void;
      }
    ) {
      this.rows = rows.map((row) => {
        const $row = RowContent.make(row.content);
        $row.onpointerenter = () =>
          callbacks.onHoveredRow({
            row,
            $row,
          });
        GESTURE_MANAGER.addPointerEvent($row, {
          onClick: () => callbacks.onSelectedRow(row),
        });
        return {
          row,
          $row,
        };
      });
    }

    first(): Option<BoundRow<Data>> {
      return this.rows[0];
    }

    next(row: BoundRow<Data>, offset: number): Option<BoundRow<Data>> {
      const idx = this.rows.findIndex((curr) => curr.row == row.row);
      if (idx == -1) return null;
      return this.rows[(idx + offset + this.rows.length) % this.rows.length];
    }

    bind(searchQuery: Option<string>): {
      candidate: Option<BoundRow<Data>>;
      rows: BoundRow<Data>[];
    } {
      const rows = this.rows;
      if (!searchQuery || searchQuery == null) {
        return {
          candidate: null,
          rows,
        };
      }

      const results = this.search(searchQuery);
      return {
        candidate: results[0]?.row,
        rows: results.map((r) => r.row),
      };
    }

    private search(query: string): Dropdown.SearchResult<Data>[] {
      const distances: Dropdown.SearchResult<Data>[] = this.rows.map(
        ({ row, $row }) => {
          let minDistance = Number.MAX_SAFE_INTEGER;
          let minDistanceIndex: Option<string>;
          let rowDisplay = typeof row.content == "string" ? row.content : null;

          for (const index of [rowDisplay, ...(row.searchIndicies ?? [])]) {
            if (index == null) continue;
            const distance = EditDistance.distance(
              query.toLocaleLowerCase(),
              index.toLocaleLowerCase()
            );
            if (minDistance > distance) {
              minDistance = distance;
              minDistanceIndex = index;
            }
          }
          return {
            row: {
              row,
              $row,
            },
            minDistance,
            minDistanceIndex,
            hidden: query.length > 0 && minDistance > 8,
          };
        }
      );
      return distances.sort((a, b) => a.minDistance - b.minDistance);
    }
  }

  export class ScrollAncestorObserver {
    private $e: HTMLElement;
    private $watchedAncestors: Option<HTMLElement[]>;
    private callback: EmptyFunction;
    private onScroll = (e: Event) => this.callback();

    constructor($e: HTMLElement, callback: () => void) {
      this.$e = $e;
      this.callback = callback;
    }

    observe() {
      if (this.$watchedAncestors) {
        this.unobserve();
      }

      this.$watchedAncestors = ScrollAncestorObserver.getScrollAncestors(
        this.$e
      );
      for (const $parent of this.$watchedAncestors) {
        $parent.addEventListener("scroll", this.onScroll, {
          passive: true,
        });
      }
    }

    unobserve() {
      if (!this.$watchedAncestors) return;

      for (const $parent of this.$watchedAncestors) {
        $parent.removeEventListener("scroll", this.onScroll);
      }
      this.$watchedAncestors = null;
    }

    static getScrollAncestors($e: HTMLElement): HTMLElement[] {
      const r: HTMLElement[] = [];

      let $parent = $e.parentElement;
      while ($parent) {
        const overflowY = window.getComputedStyle($parent).overflowY;
        if (overflowY === "auto" || overflowY === "scroll") {
          // return parent; // Return the scrollable parent
          r.push($parent);
        }
        $parent = $parent.parentElement;
      }

      return r;
    }
  }
}

// setCandidateSelectedRow(row: Dropdown.Row<Data>, scrollTo: boolean = true) {
//   this.candidateSelectedRow = row;
//   const $row = nonnull(this.rowToElement.get(row));
//   const $parent = nonnull($row.parentElement);
//   $$.measure(() => {
//     const scrollTop = $parent.scrollTop;
//     const r = $row.getBoundingClientRect();
//     const parentR = $parent.getBoundingClientRect();

//     const bgBounds = {
//       top: r.top - parentR.top + scrollTop - 1,
//       bottom: r.top - parentR.top + scrollTop + r.height,
//     };
//     const $buttonBackground = this.$("button-background");

//     $$.mutate(() => {
//       $buttonBackground.style.transform = `translate(0px, ${bgBounds.top}px)`;
//       $buttonBackground.style.height = r.height + "px";

//       if (scrollTo) {
//         const viewport = {
//           top: scrollTop,
//           bottom: scrollTop + parentR.height,
//         };
//         const delta = {
//           top: bgBounds.top - viewport.top,
//           bottom: bgBounds.bottom - viewport.bottom,
//         };
//         if (delta.bottom > 0) {
//           $parent.scroll({
//             top: scrollTop + delta.bottom + 8,
//           });
//         } else if (delta.top < 0) {
//           $parent.scroll({
//             top: scrollTop + delta.top - 8,
//           });
//         }
//       }
//     });
//   });
// }
