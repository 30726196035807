import { LonaWebComponent, template } from "../component";
import { component } from "../component-decorators";
import { css } from "../component-styles";

@component({
  name: "std-switch",
})
export class Switch extends LonaWebComponent {
  static $styles = [
    css`
      #container {
        position: relative;
        height: 20px;
        width: 32px;
        background-color: var(--selected-color);
        border-radius: 20px;
        transition: background-color 0.15s ease;
      }
      #thumb {
        position: absolute;
        top: 2px;
        left: 2px;
        height: 16px;
        width: 16px;
        border-radius: 8px;
        background-color: white;
        transition: transform 0.15s ease;
      }
      :host([on]) #thumb {
        transform: translateX(11px);
      }
      :host([on]) #container {
        background-color: var(--blue);
      }
    `,
  ];
  static $html = template`
    <div id=container>
      <div id=thumb></div>
    </div>
  `;
}
