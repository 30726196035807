import { LonaWebComponent, template } from "../component";
import { component } from "../component-decorators";
import { css } from "../component-styles";
import { $ } from "../dom-selectors";
import { $$ } from "../fastdom";
import { GESTURE_MANAGER } from "../gesture-manager";
import { Button } from "./button/button";
import { Tooltip } from "@lona/components/tooltip";

@component({
  name: "std-sidebar-expand-button",
})
export class SidebarExpandButton extends LonaWebComponent {
  onClick: EmptyFunction = () => {
    const target = this.getAttribute("data-target");
    if (!target) return;
    $$.mutate(() => {
      $(target).toggleAttribute("no-transition", false);
      $(target).toggleAttribute("stickied");
      this.toggleAttribute("revealed");
    });
  };

  constructor() {
    super();
    GESTURE_MANAGER.addPointerEvent(this.$("root"), {
      onClick: () => this.onClick(),
    });
  }

  static $styles = [
    Button.$style,
    Tooltip.$style,
    css`
      button {
        position: relative;
        width: 36px;
        height: 28px;
        color: var(--icon-primary-color);
      }
    `,
    css`
      svg {
        transition: opacity 0.3s ease-in-out;
      }

      #unopened-icon,
      :host([revealed]) #opened-icon {
        opacity: 1;
      }

      #opened-icon,
      :host([revealed]) #unopened-icon {
        opacity: 0;
      }
    `,
  ];

  static $icons: string[] = [
    /* xml */ `
    <symbol id="three-horizontal-lines" fill="currentColor" viewBox="0 0 16 16">
      <path fill-rule="evenodd"
        d="M2.5 12a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5z" />
    </symbol>
    <symbol id="double-chevron-right" fill="currentColor" viewBox="0 0 14 14">
      <path
        d="M7 12.025L8.225 13.25L14 7.125L8.225 1L7 2.225L11.55 7.125L7 12.025ZM0 12.025L1.225 13.25L7 7.125L1.225 1L8.56743e-07 2.225L4.55 7.125L0 12.025Z">
      </path>
    </symbol>
  `,
  ];

  static $html: Option<HTMLTemplateElement> = template`
    <button flat id=root aria-label="Toggle sidebar">
      <svg id=unopened-icon width="20" height="20" fill="currentColor" style="position: absolute; top: 4px; left: 8px">
        <use href="#three-horizontal-lines" />
      </svg>
      <svg id=opened-icon width="14" height="14" fill="currentColor"
        style="position: absolute; top: 7px; left: 11px; transform: rotate(180deg)">
        <use href="#double-chevron-right" />
      </svg>
      <std-tooltip position=right>
        <std-col>
          <div>Toggle sidebar open</div>
          <kbd style=color:#888>s</kbd>
        </std-col>
      </std-tooltip>
    </button>
  `;
}
