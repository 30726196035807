import "@lona/components/switch";

import { LonaWebComponent, template } from "@lona/component";
import { component } from "@lona/component-decorators";
import { css } from "@lona/component-styles";
import { Lazy, LazySync } from "@lona/lazy";
import { Pip } from "@lona/ui/pip";
import { DomUtils } from "../dom";
import { ButtonGroupRow } from "../components/button-group-row";
import { DevMode } from "../dev-mode";
import { DevTools } from "../dev-tools";
import { AuthApi } from "../auth/auth";
import { dev } from "../log";
import { Typography } from "../ui/typography";

const DEV_MODE_ITEMS: ButtonGroupRow.Item<DevMode>[] = [
  {
    label: "Beta",
    identifier: "beta",
  },
  {
    label: "RC",
    identifier: "rc",
  },
  {
    label: "Prod",
    identifier: "prod",
  },
];

const LOGS: Map<string, any> = new Map();

@component({
  name: "std-admin-controls",
})
export class AdminControls extends LonaWebComponent {
  private $devModeSwitch = DomUtils.hydrate<ButtonGroupRow<DevMode>>(
    this.$("dev-mode-switch")
  );

  static _instance = new LazySync<AdminControls>(() => {
    const $a = AdminControls.make();
    $a.bind();
    return $a;
  });
  static get instance() {
    return this._instance.get();
  }

  static async toggleReveal() {
    const r = await AuthApi.get("/auth/id");
    if (!r.User || !r.User["isServiceAccount"]) return;
    Pip.show(this.instance);
  }

  static log(key: string, value: any) {
    LOGS.set(key, value);
    if (!AdminControls._instance.hasResolved) return;
    AdminControls.instance.render();
  }

  bind() {
    this.$devModeSwitch.bind(DevTools.getMode(), DEV_MODE_ITEMS);
  }

  render() {
    DomUtils.clearChildren(this);
    for (const [k, v] of LOGS.entries()) {
      const $content = Typography.p(JSON.stringify(v, null, 2));
      this.appendChild($content);
    }
  }

  static $styles = [
    css`
      #root {
        height: 100%;
        width: 100%;
        padding: 20px;
        background: var(--background-color);
        box-shadow: var(--box-shadow);
        border-radius: 12px;
        z-index: 999999999;
      }

      #logs {
        margin-top: 20px;
        white-space: break-spaces;
      }
    `,
  ];

  static $html: Option<HTMLTemplateElement> = template`
    <std-col id=root>
      <std-row style=align-items:center;>
        <p>Dev</p>
        <std-spacer></std-spacer>
        <std-button-group-row id="dev-mode-switch"></std-button-group-row>
      </std-row>
      <std-col id=logs>
        <slot></slot>
      </std-col>
    </std-col>
  `;
}
