import { Dropdown } from "./components/dropdown";
import { EditableText } from "./components/editable-text";
import { ListCellLayout } from "./components/list/list-cell-layout";
import { DomUtils } from "./dom";
import { DomEventUtils } from "./dom-event-utils";
import { Typography } from "./ui/typography";

export namespace Div {
  export const make = () => document.createElement("div");
  export const makeText = (text: string, placeholder?: string): HTMLElement => {
    const $e = Typography.p(text);
    $e.textContent = text;
    if (placeholder) $e.setAttribute("placeholder", placeholder);
    return $e;
  };
  export const makeSelectableText = (text: Option<string>): HTMLElement => {
    const $e = Typography.p(text ?? "");
    $e.textContent = text ?? "";
    $e.style.userSelect = "all";
    $e.style.pointerEvents = "all";
    $e.onpointerdown = DomEventUtils.STOP_PROPAGATION;
    $e.onpointerup = DomEventUtils.STOP_PROPAGATION;
    $e.ondblclick = DomEventUtils.STOP_PROPAGATION;
    return $e;
  };
}

export namespace Column {
  export const make = () => document.createElement("std-col");

  class Builder {
    private rows: HTMLElement[] = [];

    text(
      text: string,
      options?: Optional<{
        tag: Typography.Tag;
        size: Typography.Size;
      }>
    ): Builder {
      const $text = Typography.text(text, options);
      DomUtils.assignStyles($text, {
        marginInline: "8px",
        "--margin-bottom": "8px",
      });
      this.rows.push($text);
      return this;
    }

    title(
      text: string,
      onTextChange: Option<(t: Option<string>) => void> = () => {}
    ): Builder {
      const $text =
        onTextChange != null
          ? (() => {
              const $e = EditableText.makeWith(text, onTextChange, {
                placeholder: "Untitled",
                withOutline: true,
              });
              $e.enableEditing(true);
              return $e;
            })()
          : Typography.text(text, {
              tag: "h1",
            });
      DomUtils.assignStyles($text, {
        marginInline: "8px",
        marginBlock: "12px",
        "--font-size": "var(--h6-size)",
      });
      this.rows.push($text);
      return this;
    }

    dropdown<Data>(
      rows: Dropdown.Row<Data>[],
      onSelectedRow: (row: Dropdown.Row<Data>) => void
    ): Builder {
      const $dropdown = Dropdown.Legacy.make() as Dropdown<Data>;
      $dropdown.style.width = "100%";
      $dropdown.bind(rows, onSelectedRow);
      this.rows.push($dropdown);
      return this;
    }

    cell(text: string, options?: Optional<{}>): Builder {
      this.rows.push(
        ListCellLayout.makeText({
          title: text,
        })
      );
      return this;
    }

    row($r: HTMLElement): Builder {
      this.rows.push($r);
      return this;
    }

    build(): HTMLElement {
      const $c = Column.make();
      $c.style.padding = "8px";
      $c.append(...this.rows);
      return $c;
    }
  }

  export function builder(): Builder {
    return new Builder();
  }
}

export namespace Row {
  export const make = () => document.createElement("std-row");
}

export namespace Center {
  export const make = () => document.createElement("std-center");
}

export namespace Iframe {
  export const make = () => document.createElement("iframe");
}

export namespace H1 {
  export const make = () => document.createElement("h1");
}

export namespace H2 {
  export const make = () => document.createElement("h2");
}

export namespace Hr {
  export const make = () => document.createElement("hr");
}

export namespace ImageElement {
  export const make = () => document.createElement("img");
}

export namespace Kbd {
  export const make = () => document.createElement("kbd");
}

export namespace Span {
  export const make = () => document.createElement("span");
}
