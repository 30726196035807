/**
 * https://iconscout.com/unicons/explore/line
 */

import { template } from "../component";
import { Div } from "../component-builtin";
import { replaceAll } from "../polyfill";
import { Utils } from "../utils";

export namespace LonaIcons {
  export type RawSvg = string;

  export type Props = Optional<{
    margin: string;
    color: string;
    sizePx: number;
  }>;

  export function make(svgString: RawSvg, options?: Props): HTMLElement {
    const $e = Div.make();
    $e.appendChild($template(svgString).content.cloneNode(true));
    $e.style.display = "flex";
    $e.style.alignItems = "center";
    $e.style.justifyContent = "center";
    const sizePx = (options?.sizePx ?? 16) + "px";
    $e.style.height = sizePx;
    $e.style.width = sizePx;
    $e.style.color = options?.color ?? "var(--secondary-icon-color)";
    options?.margin && ($e.style.margin = options.margin);
    return $e;
  }

  export function $svg(svgString: RawSvg): SVGElement {
    const svg = $template(svgString).content.cloneNode(true) as SVGElement;
    svg.slot = "icon";
    return svg;
  }

  export const $template: (svgString: RawSvg) => HTMLTemplateElement =
    Utils.memoize(template);

  export function symbol(svgString: RawSvg): string {
    return replaceAll(svgString, "svg", "symbol");
  }
}

export { default as AngleRightIcon } from "@lona/icons/angle-right.svg";
export { default as ArrowBackIcon } from "@lona/icons/arrow-back.svg";
export { default as ArrowDownIcon } from "@lona/icons/arrow-down.svg";
export { default as ArrowsAngleExpandIcon } from "@lona/icons/arrows-angle-expand.svg";
export { default as CalendarIcon } from "@lona/icons/calendar.svg";
export { default as ClockIcon } from "@lona/icons/clock.svg";
export { default as CheckCircleFilledIcon } from "@lona/icons/check-circle-filled.svg";
export { default as CheckmarkIcon } from "@lona/icons/checkmark.svg";
export { default as CircleIcon } from "@lona/icons/circle.svg";
export { default as DragIcon } from "@lona/icons/drag.svg";
export { default as FormulaIcon } from "@lona/icons/formula.svg";
export { default as GearIcon } from "@lona/icons/gear.svg";
export { default as GridIcon } from "@lona/icons/grid.svg";
export { default as JournalIcon } from "@lona/icons/journal.svg";
export { default as HashIcon } from "@lona/icons/hash.svg";
export { default as MoonIcon } from "@lona/icons/moon.svg";
export { default as NumberIcon } from "@lona/icons/number.svg";
export { default as PencilIcon } from "@lona/icons/pencil.svg";
export { default as PlusIcon } from "@lona/icons/plus.svg";
export { default as SnapCenterIcon } from "@lona/icons/snap-center.svg";
export { default as SnapLeftIcon } from "@lona/icons/snap-left.svg";
export { default as TextIcon } from "@lona/icons/text.svg";
export { default as Text2Icon } from "@lona/icons/text2.svg";
export { default as ThreeDotsIcon } from "@lona/icons/three-dots.svg";
export { default as TrashIcon } from "@lona/icons/trash.svg";
export { default as XIcon } from "@lona/icons/x.svg";
