import { LonaWebComponent, template } from "@lona/component";
import { component } from "@lona/component-decorators";
import { Card } from "./card";
import { css } from "@lona/component-styles";
import { grainOverlayStyle } from "../lib/study-card";

@component({
  name: "langilo-reward-card",
})
export class RewardCard extends LonaWebComponent {
  private $card = Card.make();

  constructor() {
    super();

    this.$card.appendChild(RewardCard.$front.content.cloneNode(true));
    this.$card.appendChild(RewardCard.$back.content.cloneNode(true));
    this.shadowRoot!.appendChild(this.$card);
  }

  flip() {
    this.$card.flip();
  }

  bind() {}

  static frontStyle = css`
    #front {
      user-select: none;
      padding: 20px;
      height: 100%;
    }

    #title-container {
      height: 100%;
      width: 100%;
      align-items: center;
      justify-content: center;
    }

    #title {
      --font-size: 5rem;
    }
  `;
  static $front = template`
    <std-col id=front slot=front>
      <std-col id=title-container>
        <h1 id=title>🎉</h1>
      </std-col>
      <div class=grain-overlay></div>
    </std-col>
  `;

  static backStyle = css`
    #back {
      user-select: none;
      padding: 8px;
      width: 100%;
      height: 100%;
    }

    #card-content {
      width: 100%;
      height: 100%;
      border-radius: 16px;
      background: white;
      padding: 20px;
    }

    #container {
      background: #444;
      padding: 12px;
      color: white;
      border-radius: 8px;
    }
  `;
  static $back = template`
    <std-col id=back slot=back>
      <std-col id=card-content>
        <std-spacer></std-spacer>
        <std-col style=align-items:center;><p style=--font-size:4rem>🐶</p></std-col>
        <std-spacer></std-spacer>
        <div id=container>
          <h1 class=h6>⭐️ Your first set!</h1>
        </div>
        <p style=margin-inline:8px;--margin-top:12px;>You've earned 3 additional cards!</p>
      </std-col>
      <div class=grain-overlay></div>
    </std-col>
  `;

  static $styles = [
    grainOverlayStyle,
    RewardCard.frontStyle,
    RewardCard.backStyle,
    css`
      :host {
        transition: transform 0.3s ease, filter 0.3s ease, opacity 0.3s ease;
        --background: #444;
      }
    `,
  ];
}
