import { LonaWebComponent, template } from "@lona/component";
import { component } from "@lona/component-decorators";
import { LazySync } from "@lona/lazy";
import { css } from "../../component-styles";
import { DomUtils } from "../../dom";
import { $$ } from "../../fastdom";
import { ViewportObserver } from "../../ui/viewport-observer";
import { NumRange } from "../../range";
import { MathUtils } from "../../math";
import { SimpleKeyframe } from "../../keyframe";
import { Toast } from "./toast";

export const TOAST_LINGER_DURATION_MS = 10_000_000; // 6s

@component({
  name: "std-toaster",
})
export class Toaster extends LonaWebComponent {
  static ZINDEX = 999999;

  private $$ = {
    root: this.$("root"),
  };

  private static _instance: LazySync<Toaster> = new LazySync(() => {
    const $toaster = new Toaster();
    $toaster.assignStyles({
      position: "fixed",
      bottom: "20px",
      right: "20px",
      zIndex: String(9999999),
    });
    document.body.appendChild($toaster);
    return $toaster;
  });
  static get instance(): LazySync<Toaster> {
    return this._instance;
  }

  private get $toasts(): HTMLElement[] {
    return [
      ...this.$<HTMLSlotElement>("toasts").assignedElements(),
    ] as HTMLElement[];
  }

  get expanded(): boolean {
    return this.$$.root.hasAttribute("expanded");
  }

  private viewportObserver = new ViewportObserver(this.$$.root, true);
  private cachedExpandedRects: Option<DOMRect[]>;
  private toastDurations: WeakMap<HTMLElement, number> = new WeakMap();
  private currentTimeout: Option<number>;

  constructor() {
    super();

    let animating = false;
    const startAnimating = () => {
      animating = true;
    };
    const stopAnimating = () => {
      setTimeout(() => {
        animating = false;
      }, 300);
    };

    this.$$.root.addEventListener("pointerenter", () => {
      if (this.currentTimeout) {
        clearTimeout(this.currentTimeout);
        this.currentTimeout = null;
      }
      if (this.$$.root.hasAttribute("expanded")) return;
      if (animating) return;
      startAnimating();
      this.viewportObserver.disable();
      DomUtils.flipAnimation(
        this.$toasts,
        () => {
          this.$$.root.toggleAttribute("expanded", true);
          this.$$.root.scrollTop = 0;
          this.viewportObserver.recalculateViewportSize();
          this.viewportObserver.recalculateViewport();
          this.rebindExpandedSync();
        },
        {
          shouldMeasure: false,
        }
      );
      stopAnimating();
      $$.defer(() => this.viewportObserver.enable());
    });
    this.$$.root.addEventListener("pointerleave", () => {
      this.queueNextToastTimeout();
      if (!this.$$.root.hasAttribute("expanded")) return;
      if (animating) return;
      startAnimating();
      DomUtils.flipAnimation(this.$toasts, () => this.toggleExpanded(false));
      stopAnimating();
    });
    this.viewportObserver.addViewportListener(() => {
      if (!this.$$.root.hasAttribute("expanded")) return;
      // requestAnimationFrame(() => {
      //   this.rebindExpandedSync();
      // });
      this.rebindExpandedSync();
    });
  }

  toggleExpanded(force?: boolean) {
    const wasExpanded = this.$$.root.hasAttribute("expanded");
    const expanded = this.$$.root.toggleAttribute(
      "expanded",
      force ?? !wasExpanded
    );
    if (wasExpanded == expanded) return;
    this.rebind();
  }

  private rebind() {
    if (this.$$.root.hasAttribute("expanded")) {
      this.rebindExpandedSync();
    } else {
      this.rebindNotExpanded();
    }
  }

  private rebindNotExpanded() {
    for (const [idx, $toast] of this.$toasts.entries()) {
      const cappedIdx = Math.min(idx, 2);
      DomUtils.assignStyles($toast, {
        zIndex: String(Toaster.ZINDEX - idx),
        "--toast-transition": "",
        "--toast-transform": `scale(${1 - cappedIdx * 0.04}) translateY(${
          cappedIdx * -12
        }px)`,
        filter: idx == 0 ? "" : idx == 1 ? "blur(0.7px)" : "blur(1.4px)",
      });
    }
  }

  /**
   * @note: this occurs within the onScroll callback (capturing). we need to apply
   * the transform immediately so there's no jitter
   */
  private rebindExpandedSync() {
    const viewport = this.viewportObserver.viewport;
    const $toasts = this.$toasts;
    if ($toasts.length <= 1) return;

    $$.measure(() => this.maybeRecalculatedExpandedRects());
    $$.mutate(() => {
      const firstOffset = this.cachedExpandedRects![0].bottom;
      for (const [idx, $toast] of $toasts.entries()) {
        const rect = this.cachedExpandedRects![idx];
        const start = -rect.bottom + firstOffset;
        const window: NumRange = {
          start,
          end: start + rect.height,
        };
        $toast.getBoundingClientRect();

        const deltaStart = viewport.start - window.start;
        if (deltaStart > 0) {
          const progress = Math.min(1, deltaStart / 20);

          DomUtils.assignStyles($toast, {
            zIndex: String(idx),
            "--toast-transition": "none",
            "--toast-transform": `translateY(${-deltaStart}px) scale(${MathUtils.lerp(
              1,
              0.96,
              progress
            )})`,
            filter: `blur(${MathUtils.lerp(0, 0.7, progress)}px)`,
          });
          continue;
        }

        if (rect.height > viewport.end - viewport.start) {
          continue;
        }

        const deltaEnd = viewport.end - window.end;
        if (deltaEnd < 0) {
          const progress = Math.min(1, -deltaEnd / 20);

          DomUtils.assignStyles($toast, {
            zIndex: String(Toaster.ZINDEX - idx),

            "--toast-transition": "none",
            "--toast-transform": `translateY(${-deltaEnd}px) scale(${MathUtils.lerp(
              1,
              0.96,
              progress
            )})`,
            filter: `blur(${MathUtils.lerp(0, 0.7, progress)}px)`,
          });
          continue;
        }

        DomUtils.assignStyles($toast, {
          zIndex: String(Toaster.ZINDEX - idx),
          "--toast-transition": "none",
          "--toast-transform": `none`,
          filter: "",
        });
      }
    });
  }

  static show($toast: Toast) {
    const $toaster = Toaster.instance.get();
    $toaster.show($toast);
  }

  show($toast: Toast, timeoutMs: number = TOAST_LINGER_DURATION_MS) {
    const removeToast = () => {
      this.cachedExpandedRects = null;
      DomUtils.flipAnimation(
        this.$toasts,
        () => {
          DomUtils.removeFromDom($toast);
          this.rebind();
          if (this.$toasts.length == 0) {
            $$.mutate(() => {
              this.$$.root.toggleAttribute("expanded", false);
            });
          }
        },
        {
          shouldMeasure: true,
        }
      );
    };

    $toast.onClickClose = removeToast;
    $toast.onClickUndo = removeToast;

    this.prepend($toast);
    if (this.$toasts.length == 1) {
      this.animate(SimpleKeyframe.builder().slideIn(0, 20).fadeIn().build(), {
        duration: 150,
      });
    }

    this.toastDurations.set($toast, timeoutMs);
    if (this.currentTimeout) {
      clearTimeout(this.currentTimeout);
      this.currentTimeout = null;
    }
    this.queueNextToastTimeout();

    this.rebind();
    if (this.expanded) {
      this.maybeRecalculatedExpandedRects();
    } else {
      this.cachedExpandedRects = null;
    }
  }

  private queueNextToastTimeout() {
    const $first = this.$toasts[0];
    if (!$first) {
      this.currentTimeout = null;
      return;
    }
    const timeoutMs = this.toastDurations.get($first)!;
    this.currentTimeout = setTimeout(() => {
      $first.animate(
        SimpleKeyframe.builder().slideOut(0, 20).fadeOut().build(),
        {
          duration: 150,
        }
      ).onfinish = () => {
        DomUtils.removeFromDom($first);
        this.rebind();
        this.queueNextToastTimeout();
      };
    }, timeoutMs);
  }

  private maybeRecalculatedExpandedRects() {
    if (this.cachedExpandedRects) return;
    this.cachedExpandedRects = this.$toasts.map(($t) =>
      $t.getBoundingClientRect()
    );
  }

  static $styles = [
    css`
      #root {
        overflow: visible;
      }

      slot::slotted(*) {
        position: absolute;
        right: 0px;
        bottom: 0px;
        max-height: 160px;
      }

      #root[expanded] {
        display: flex;
        flex-direction: column-reverse;
        overflow: scroll;
        height: 300px;
      }

      #root[expanded] slot::slotted(*) {
        position: relative;
        will-change: transform;
      }

      #root[expanded] slot::slotted(*:not(:first-child)) {
        margin-bottom: 2px;
      }
    `,
  ];

  static $html: Option<HTMLTemplateElement> = template`
    <div id=root>
      <slot id=toasts></slot>
    </div>
  `;
}
