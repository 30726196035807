export namespace GoogleAuth {
  function corsImport(s: string): Promise<Api> {
    return new Promise((resolve, _reject) => {
      const script = document.createElement("script");
      script.setAttribute("src", "https://accounts.google.com/gsi/client");
      // @ts-ignore
      script.onload = () => resolve(google);
      document.head.appendChild(script);
    });
  }

  type Client = {
    requestCode: () => void;
  };

  type ClientCodeResponse = {
    code: string;
  };

  type Api = {
    accounts: {
      oauth2: {
        initCodeClient: (config: {
          client_id: string;
          scope: string;
          ux_mode: "popup";
          callback: (response: ClientCodeResponse) => void;
        }) => Client;
      };
    };
  };

  async function load(): Promise<Api> {
    return await corsImport("https://accounts.google.com/gsi/client");
  }

  export async function login(clientId: string, scope: string[]): Promise<string> {
    const google = await load();
    return new Promise((resolve) => {
      // https://developers.google.com/identity/oauth2/web/guides/use-code-model#popup-mode
      const client = google.accounts.oauth2.initCodeClient({
        client_id: clientId,
        scope: scope.join(" "),
        ux_mode: "popup",
        callback: (response) => resolve(response.code),
      });
      client.requestCode();
    });
  }
}
