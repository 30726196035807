import { LonaWebComponent, template } from "@lona/component";
import { component } from "@lona/component-decorators";
import { css } from "@lona/component-styles";
import { ListCellLayout } from "@lona/components/list/list-cell-layout";
import { AllCardList } from "../routes/index.root";
import { LiveData } from "@lona/livedata";
import { StudyCard } from "./study-card";
import { DomUtils } from "@lona/dom";
import { Streaks } from "@lona/components/streaks";

@component({ name: "langilo-sidebar-content" })
export class SidebarContent extends LonaWebComponent {
  private $vocabList = this.$("vocab-list");
  private cards: Option<LiveData<AllCardList>>;
  private $streaks = Streaks.make();

  constructor() {
    super();

    this.$("streaks").replaceWith(this.$streaks);
    this.$streaks.id = "streaks";
  }

  bindLive(cards: LiveData<AllCardList>) {
    this.cards = cards;
    this.cards.addListener(this, (cardList) => this.bind(cardList));
    this.bind(cards.get());
  }

  private bind(cards: AllCardList) {
    DomUtils.clearChildren(this);
    for (const card of cards.allCards) {
      this.appendChild(SidebarContent.makeCardRow(card));
    }
  }

  static makeCardRow(prop: StudyCard.Prop): HTMLElement {
    const $c = ListCellLayout.makeText({
      title: prop.title,
      caption: prop.definition,
    });
    $c.slot = "vocab-list";
    return $c;
  }

  static $styles = [
    css`
      :host {
        height: 100%;
        color: var(--primary-text-color);
      }

      #root {
        padding: 12px;
        height: 100%;
      }

      .header {
        --margin-bottom: 12px;
        margin-left: 8px;
      }
    `,
  ];
  static $html: Option<HTMLTemplateElement> = template`
    <std-col id=root>
      <h2 id=vocab-list-title class="header h5">Vocabulary List</h2>
      <std-col id=vocab-list>
        <slot name=vocab-list></slot>
      </std-col>
      <std-spacer></std-spacer>
      <std-col>
        <h2 class="header h5">Streaks</h2>
        <div id=streaks></div>
      </std-col>
    </std-col>
  `;
}
