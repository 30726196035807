import { LonaWebComponent, template } from "@lona/component";
import { component } from "@lona/component-decorators";
import { css } from "@lona/component-styles";
import { DomUtils } from "@lona/dom";
import { dev } from "@lona/log";
import { Actions, AllCardList, State } from "../routes/index.root";
import { LiveData } from "@lona/livedata";
import { LonaIcons, PlusIcon } from "@lona/ui/icons";
import { $, $qsa } from "@lona/dom-selectors";
import { MAP } from "./examples";

@component({
  name: "langilo-vocab-grid",
})
export class VocabGrid extends LonaWebComponent {
  private $root = this.$("root");
  private initialized = false;

  private cards: Option<LiveData<AllCardList>>;
  private $cells: HTMLElement[][] = [];

  bindLive(cards: LiveData<AllCardList>) {
    this.cards = cards;
    this.cards.addListener(this, (cardList) => this.bind(cardList));
    this.bind(cards.get());
  }

  private bind(cards: AllCardList) {
    this.initIfNecessary();
    for (const prop of cards.allCards) {
      const $cell = this.$cells[prop.coord.r][prop.coord.c];
      $cell.replaceChildren(prop?.title ?? "");
      $cell.toggleAttribute("collected", true);
      DomUtils.assignStyles($cell, {
        "--background": prop?.background ?? "",
      });

      if (State.newCardsToSelect.get() == 0) continue;
      for (let r = -1; r <= 1; ++r) {
        for (let c = -1; c <= 1; ++c) {
          if (r != 0 && c != 0) continue;
          const coord = {
            row: prop.coord.r + r,
            col: prop.coord.c + c,
          };
          if (cards.getProp(coord) != null) continue;
          const $cell = this.$cells[coord.row][coord.col];
          $cell.toggleAttribute("can-select", true);

          if ($cell.hasAttribute("special-card")) continue;
          const $addIcon = LonaIcons.make(PlusIcon);
          $addIcon.classList.add("add-icon");
          $cell.replaceChildren($addIcon);
        }
      }
    }
  }

  private initIfNecessary() {
    if (!this.initialized) {
      this.init();
    }
  }

  private init() {
    this.initialized = true;

    const cellSize = {
      height: 60,
      width: 40,
    };

    const rowsRadius = 16;
    const colsRadius = 16;
    const rows = rowsRadius * 2 + 1;
    const cols = colsRadius * 2 + 1;
    // const size = {
    //   width: cols * cellSize.width,
    //   height: rows * cellSize.height,
    // };

    for (let r = -rowsRadius; r < rowsRadius; ++r) {
      this.$cells[r] = [];
      for (let c = -colsRadius; c < colsRadius; ++c) {
        const coord = {
          r,
          c,
        };
        const worldCoord = {
          x: c * cellSize.width,
          y: r * cellSize.height,
        };

        const $cell = GridCell.make()
          .assignStyles({
            transform: `translate(${worldCoord.x}px, ${worldCoord.y}px)`,
            height: cellSize.height - 2 + "px",
            width: cellSize.width - 2 + "px",
          })
          .addPointerEvent({
            onClick: () => {
              if (!$cell.hasAttribute("can-select")) return;

              // TODO: lots of manual state management - could be buggy
              $cell.toggleAttribute("can-select", false);
              Actions.createNewCard(coord);
            },
          });
        $cell.classList.add("cell");
        const flatIdx = (r + rowsRadius) * cols + (c + colsRadius);
        if (MAP[flatIdx] != ".") {
          $cell.toggleAttribute("special-card");
          switch (MAP[flatIdx]) {
            case "s":
              $cell.replaceChildren("🌿");
              break;
            case "t":
              $cell.replaceChildren("🌲");
              break;
            case "f":
              $cell.replaceChildren("🌻");
              break;
          }
        }

        this.$cells[r][c] = $cell;
        this.appendChild($cell);
      }
    }

    const $cardsAvailable = this.$("additional-new-cards");
    State.newCardsToSelect.addListener(
      $cardsAvailable,
      this.bindNewCardsAvailable.bind(this)
    );
    this.bindNewCardsAvailable(State.newCardsToSelect.get());
  }

  private bindNewCardsAvailable(n: number) {
    if (n == 0) {
      this.$("additional-new-cards").textContent =
        "Complete more quizzes to add more cards";
    } else {
      this.$("additional-new-cards").textContent = `Select ${n} new cards`;
    }
    this.toggleAttribute("no-new-cards", n == 0);

    $qsa("[can-select]", this).forEach(($e) => {
      $e.toggleAttribute("no-new-cards", n == 0);
    });
  }

  static $styles = [
    css`
      :host {
        overflow: hidden;
      }

      :host([hide]) langilo-grid-cell {
        pointer-events: none;
      }

      #root {
        width: 100%;
        height: 100%;
        overflow: hidden;
      }

      #root::after {
        content: "";
        position: absolute;
        inset: 0;
        pointer-events: none;
        box-shadow: inset 0px 0px 20px 32px white;
        z-index: 9;
      }

      #viewport {
        position: absolute;
        left: 50%;
        top: calc(50% - 80px);
        transition: transform 0.3s ease;
        transform: translate(-50%, -50%);

        background: white;
      }

      #context-row {
        max-width: 440px;
        position: absolute;
        bottom: 8px;
        left: 50%;
        transform: translateX(-50%);
        z-index: 10;
      }

      #select-vocab-title {
        align-self: center;
        border-radius: 12px;
        background: #444;
        padding: 8px;
        color: white;
        box-shadow: var(--box-shadow);
      }

      #select-vocab-info {
        border-radius: 8px;
        padding: 8px;
        margin-top: 12px;
        color: var(--secondary-text-color);
        background: var(--card-color);
      }

      p:not(:last-child) {
        --margin-bottom: 8px;
      }

      :host([no-new-cards]) .add-icon {
        display: none;
      }
    `,
  ];

  static $html: Option<HTMLTemplateElement> = template`
    <div id=root>
      <div id=viewport>
        <div id=grid>
          <slot></slot>
        </div>
      </div>

      <std-col id=context-row>
        <div id=select-vocab-title>
          <h1 id=additional-new-cards class=p></h1>
        </div>
        <div id=select-vocab-info>
          <p>Click the + button to add cards to your deck.</p>
          <p>Access more vocabulary by doing quizzes!</p>
        </div>
      </std-col>
    </div>
  `;
}

@component({
  name: "langilo-grid-cell",
})
export class GridCell extends LonaWebComponent {
  static $styles = [
    css`
      :host {
        display: block;
        position: relative;
        font-size: 0.7rem;
        overflow: hidden;
        position: absolute;
        top: 0px;
        left: 0px;
        border: 1px solid rgba(0, 0, 0, 0.09);
        margin: 1px;
        border-radius: 8px;
        display: flex;
        align-items: center;
        justify-content: center;
        pointer-events: none;
        text-align: center;
      }

      :host([special-card]) {
        font-size: 1.5rem;
      }

      :host([collected]) {
        font-size: 0.875rem !important;
      }

      :host::before {
        position: absolute;
        content: "";
        inset: 0;
        background: var(--background, #f1e8e5);
        opacity: 0.3;
        z-index: -1;
      }

      :host([can-select]) {
        pointer-events: all;
      }

      :host(:not([no-new-cards])[can-select]):before {
        opacity: 0.8;
        cursor: pointer;
      }

      :host(:not([no-new-cards])[can-select]):hover::before {
        opacity: 1;
      }

      slot::slotted(*) {
        pointer-events: none;
      }

      :host([no-new-cards]) slot::slotted(*) {
        opacity: 0;
        display: none;
      }
    `,
  ];
  static $html: Option<HTMLTemplateElement> = template`
    <slot></slot>
  `;
}
