import { err, info } from "./log";
import { Result } from "./result";

export type ResponseError = {
  code: number;
  reason: string;
};

export class ResponseResult<Data> extends Result<Data, ResponseError> {
  static expectOrToast<D>(r: ResponseResult<D>): D {
    return r.match({
      ok: (o) => o,
      err: () => {
        throw new Error("request failed");
      },
    });
  }
}

export interface TypedResponse<R> {
  response: Response;
  data: () => Promise<R>;
}

interface FetchConfig {
  query?: any;
  body?: any;
  path?: any;
}

interface Route {
  config?: FetchConfig;
  response?: Option<any>;
}

export interface RouteList {
  [route: string]: {
    get?: Route;
    post?: Route;
    patch?: Route;
    delete?: Route;
  };
}

type ReviverMap<R extends RouteList> = {
  [K in keyof R]?: {
    get?: Reviver.Val;
    post?: Reviver.Val;
    patch?: Reviver.Val;
    delete?: Reviver.Val;
  };
};

type ExtractConfigOpt<T, Method extends string> = T extends {
  [K in Method]: { config: infer C };
}
  ? C
  : never;

type ExtractConfigReq<T, Method extends string> = ExtractConfigOpt<
  T,
  Method
> extends undefined
  ? [undefined?]
  : [ExtractConfigOpt<T, Method>];

type ExtractResponse<T, Method extends string> = T extends {
  [K in Method]: { response: infer C };
}
  ? C
  : never;

export class TypedApi<R extends RouteList> {
  constructor(public readonly revivers?: Option<ReviverMap<R>>) {}

  static make<R extends RouteList>(): TypedApi<R> {
    return new TypedApi();
  }

  static makeWith<R extends RouteList>(revivers: ReviverMap<R>): TypedApi<R> {
    return new TypedApi(revivers);
  }

  async post<K extends keyof R>(
    route: K,
    ...config: ExtractConfigReq<R[K], "post">
  ): Promise<ExtractResponse<R[K], "post">> {
    return this.wrappedSend("post", route, config ? config[0] : undefined).then(
      ResponseResult.expectOrToast
    );
  }

  async postResult<K extends keyof R>(
    route: K,
    ...config: ExtractConfigReq<R[K], "post">
  ): Promise<ResponseResult<ExtractResponse<R[K], "post">>> {
    return this.wrappedSend("post", route, config ? config[0] : undefined);
  }

  async patch<K extends keyof R>(
    route: K,
    ...config: ExtractConfigReq<R[K], "patch">
  ): Promise<ExtractResponse<R[K], "patch">> {
    return this.wrappedSend(
      "patch",
      route,
      config ? config[0] : undefined
    ).then(ResponseResult.expectOrToast);
  }

  async patchResult<K extends keyof R>(
    route: K,
    ...config: ExtractConfigReq<R[K], "patch">
  ): Promise<ResponseResult<ExtractResponse<R[K], "patch">>> {
    return this.wrappedSend("patch", route, config ? config[0] : undefined);
  }

  async get<K extends keyof R>(
    route: K,
    ...config: ExtractConfigReq<R[K], "get">
  ): Promise<ExtractResponse<R[K], "get">> {
    return this.wrappedSend("get", route, config ? config[0] : undefined).then(
      ResponseResult.expectOrToast
    );
  }

  async getResult<K extends keyof R>(
    route: K,
    ...config: ExtractConfigReq<R[K], "get">
  ): Promise<ResponseResult<ExtractResponse<R[K], "get">>> {
    return this.wrappedSend("get", route, config ? config[0] : undefined);
  }

  async del<K extends keyof R>(
    route: K,
    ...config: ExtractConfigReq<R[K], "delete">
  ): Promise<ExtractResponse<R[K], "delete">> {
    return this.wrappedSend(
      "delete",
      route,
      config ? config[0] : undefined
    ).then(ResponseResult.expectOrToast);
  }

  async delResult<K extends keyof R>(
    route: K,
    ...config: ExtractConfigReq<R[K], "delete">
  ): Promise<ResponseResult<ExtractResponse<R[K], "delete">>> {
    return this.wrappedSend("delete", route, config ? config[0] : undefined);
  }

  async wrappedSend<K extends keyof R, Config>(
    method: "get" | "post" | "delete" | "patch",
    route: K,
    config?: Config
  ): Promise<Result<any, { code: number; reason: any }>> {
    const response = await TypedApi.send(
      method,
      route as string,
      (config as Option<FetchConfig>) ?? {}
    );
    const reviver = this.revivers
      ? this.revivers[route]
        ? this.revivers[route][method]
        : null
      : null;

    if (response.status != 200) {
      return ResponseResult.err({
        code: response.status,
        reason: await TypedApi.jsonBody(response, reviver),
      });
    } else {
      return ResponseResult.ok(await TypedApi.jsonBody(response, reviver));
    }
  }

  private static async jsonBody(
    response: Response,
    reviver?: Option<Reviver.Val>
  ): Promise<Option<any>> {
    try {
      const json = await response.json();
      if (!reviver) return json;
      return Reviver.revive(json, reviver);
    } catch (e) {
      return undefined;
    }
  }

  // NOTE: return type is type erased - no way for the compiler to check
  // the return type of sendMessage so this is correct
  //
  static async send(
    method: string,
    route: string,
    config: FetchConfig
  ): Promise<Response> {
    let resolvedRoute = route;
    const matches = [...route.matchAll(/{([^}]+)}/g)];
    for (const match of matches) {
      // match = ["{param}", "param"]
      // TODO
      // Need error handling - what if config.path.query is null?
      resolvedRoute = resolvedRoute.replace(match[0], config.path[match[1]]);
    }
    const params = new URLSearchParams();
    for (const p in config.query ?? {}) {
      if (!config.query[p]) continue;
      params.append(p, config.query[p].toString());
    }
    info("network")(`[${method}]`, resolvedRoute, config.query);

    performance.mark(`[network][${route}] send start`);
    const r = await fetch(
      params.size > 0 ? `${resolvedRoute}?${params.toString()}` : resolvedRoute,
      {
        method: method.toUpperCase(),
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(config.body),
      }
    );
    performance.mark(`[network][${route}] send end`);
    performance.measure(
      `[network][${route}] send`,
      `[network][${route}] send start`,
      `[network][${route}] send end`
    );

    return r;
  }
}

export namespace Reviver {
  export type ArrayKey = "[array]";
  export const ARRAY: ArrayKey = "[array]";

  export type Handler<Value> = (s: any) => Value;
  export type Field = [string, Val];
  export type Obj = Field[];
  export type Arr = [ArrayKey, Val];
  export type Val = Arr | Obj | Handler<any>;

  export function revive(obj: Object | Array<any>, spec: Val): any {
    if (typeof spec == "function") {
      if (obj == null) return null;
      obj = spec(obj);
    } else if (Array.isArray(obj)) {
      if (spec[0] != "[array]") throw Error("expected array");
      obj = obj.map((e) => revive(e, (spec as Arr)[1]));
    } else {
      for (const [key, r] of spec as Obj) {
        if (key == "") {
          obj = revive(obj, r);
        } else if (typeof r == "function") {
          const v = obj[key];
          if (!v) continue;
          obj[key] = r(v);
        } else {
          obj[key] = revive(obj[key], r);
        }
      }
    }
    return obj;
  }
}
